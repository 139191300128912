import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import SingleWeekCoreData from "./SingleWeekCoreData";
import Footer from "./Footer";
import Breadcrumb from "../BreadCrumb/BreadCrumb";
import Chat from "./Chat";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../Login/firebase";

const styles = {
  section: "flex flex-col flex-1 w-full  h-full  pt-20 sm:pt-28 ",
  container: "flex flex-col flex-1 w-full h-full items-center",
  container__div: "flex flex-col w-full flex-1 ",
};

const SingleWeekMaybe = () => {
  const containerRef = useRef(null);
  const [user] = useAuthState(auth);
  const [qaList, setQAList] = useState([]);
  const [selectBookNumber, setSelectBookNumber] = useState("");
  const [selectedBook, setSelectedBook] = useState("");
  const [booksData, setBooksData] = useState("");
  const [question, setQuestion] = useState("");
  const [show, setShow] = useState(false);
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(true);
  const [inputQuestion, setInputQuestion] = useState("");
  const secondRoute = window.location.pathname.split("/")[2];

  const coinBreadcrumbsList = [
    { title: "Home", link: "/" },
    { title: "AI Safety Fundamentals", link: `/ai` },
    { title: secondRoute, link: `` },
  ];
  const coinBreadcrumbsListSelected = [
    { title: "Home", link: "/" },
    { title: "AI Safety Fundamentals", link: `/ai` },
    { title: selectedBook, link: `` },
  ];

  // this for for api call when user writes the question and hit enter of press send button this function has been calling
  const handleSubmit = () => {
    setQuestion(inputQuestion);
    setInputQuestion("");
    setLoading(true);
    setShow(true);
    let data = JSON.stringify({
      books: booksData,
      question: inputQuestion,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/books",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);

        setQAList([
          ...qaList,
          { question: inputQuestion, answer: response.data.result.content },
        ]);

        setAnswer("");
        if (response) {
          const storeData = JSON.stringify({
            email: user.email,
            question: inputQuestion,
            answer: response.data.result.content,
            course: "ai-fundamental-maybe",
            selected_course: selectedBook,
            week: secondRoute,
          });
          let configStore = {
            method: "post",
            url: "/api/store-response",
            headers: {
              "Content-Type": "application/json",
            },
            data: storeData,
          };
          axios
            .request(configStore)
            .then((response) => {})
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        setLoading(false);

        setQAList([
          ...qaList,
          {
            question,
            answer:
              "Something went wrong please try with another course or week",
          },
        ]);

        setAnswer("");
        console.log(error);
      });
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    // Automatically scroll to the bottom when new content is added
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [qaList]);
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className={styles.section}>
      <Breadcrumb
        breadcrumbsList={
          selectedBook ? coinBreadcrumbsListSelected : coinBreadcrumbsList
        }
        setShow={setShow}
        setSelectBookNumber={setSelectBookNumber}
        setSelectedBook={setSelectedBook}
        setQAList={setQAList}
      />
      {show === true ? (
        <Chat
          question={question}
          setQuestion={setQuestion}
          answer={answer}
          setAnswer={setAnswer}
          loading={loading}
          qaList={qaList}
          selectedBook={selectedBook}
          setQAList={setQAList}
          handleSubmit={handleSubmit}
          handleEnterKeyPress={handleEnterKeyPress}
          inputQuestion={inputQuestion}
          setInputQuestion={setInputQuestion}
        />
      ) : (
        <div className={styles.container}>
          <div className={styles.container__div}>
            {typeof window !== "undefined" &&
            window.location.pathname === "/ai/week5/maybe" ? (
              <SingleWeekCoreData
                week="week5"
                tab="maybe"
                title="Week 5 : Making AI regulations globally relevant through countries with regulations leading on AI"
                bookName1="USA vs China, The War You Can't See"
                bookName2="Compute"
                bookName3="AI Chips: What They Are and Why They Matter"
                bookName1Number="75"
                bookName2Number="76"
                bookName3Number="77"
                selectedBook={selectedBook}
                setSelectedBook={setSelectedBook}
                selectBookNumber={selectBookNumber}
                setSelectBookNumber={setSelectBookNumber}
                booksData={booksData}
                setBooksData={setBooksData}
                question={question}
                setQuestion={setQuestion}
                answer={answer}
                setAnswer={setAnswer}
                show={show}
                setShow={setShow}
                handleSubmit={handleSubmit}
                inputQuestion={inputQuestion}
                setInputQuestion={setInputQuestion}
              />
            ) : null}
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default SingleWeekMaybe;
