import React from "react";
import breadcrumbsStyles from "./Breadcrumbs.module.css";
import { Link } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";

function Breadcrumb(props) {
  const breadcrumbsList = props.breadcrumbsList;

  return (
    <div
      className={`fixed top-[61px]  z-10  sm:h-[50px]  bg-white flex items-center  w-full mx-auto text-center py-2 px-4 sm:py-1 sm:px-4 `}
    >
      <div className=" flex  items-center justify-start flex-wrap sm:justify-start sm:max-w-7xl sm:flex-row mx-auto w-full  sm:px-4 ">
        {breadcrumbsList.map((breadcrumb, i) => (
          <span
            className={`${
              i === breadcrumbsList.length - 1
                ? `flex cursor-auto items-center  ${breadcrumbsStyles.lastLink}`
                : `flex cursor-pointer items-center text-[12px] text-[#40414f]`
            } ${breadcrumbsStyles.linkStyle}`}
            key={i}
          >
            {breadcrumb.title === "core" ||
            breadcrumb.title === "further" ||
            breadcrumb.title === "maybe" ? (
              <span></span>
            ) : (
              <>
                {i === breadcrumbsList.length - 1 ? (
                  <span className="pr-0.5 sm:pr-1 text-left font-bold text-[#40414f]">
                    {breadcrumb.title}
                  </span>
                ) : (
                  <Link
                    className="pr-0.5 sm:pr-1 text-left font-medium text-[#40414f]/60"
                    to={
                      breadcrumb.link.includes("week")
                        ? `${breadcrumb.link}/core`
                        : breadcrumb.link
                    }
                  >
                    {breadcrumb.title}
                  </Link>
                )}

                {breadcrumb.title !== "" ? (
                  <AiOutlineRight className="text-black" size={12} />
                ) : null}
              </>
            )}
          </span>
        ))}
      </div>
    </div>
  );
}

export default Breadcrumb;
