import React, { useState } from "react";
import axios from "axios";
import SingleWeekCoreData from "./SingleWeekCoreData";
import Footer from "./Footer";
import Breadcrumb from "../BreadCrumb/BreadCrumb";
import Chat from "./Chat";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../Login/firebase";

const styles = {
  section: "flex flex-col flex-1 w-full  h-full  pt-20 sm:pt-28 ",
  container: "flex flex-col flex-1 w-full h-full items-center",
  container__div: "flex flex-col w-full flex-1 ",
};

const SingleWeekCore = () => {
  const [user] = useAuthState(auth);
  const [qaList, setQAList] = useState([]);

  const [selectBookNumber, setSelectBookNumber] = useState("");
  const [selectedBook, setSelectedBook] = useState("");
  const [booksData, setBooksData] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);

  const [inputQuestion, setInputQuestion] = useState("");

  const secondRoute = window.location.pathname.split("/")[2];

  const coinBreadcrumbsList = [
    { title: "Home", link: "/" },
    { title: "AI Safety Fundamentals", link: `/ai` },
    { title: secondRoute, link: `` },
  ];
  const coinBreadcrumbsListSelected = [
    { title: "Home", link: "/" },
    { title: "AI Safety Fundamentals", link: `/ai` },
    { title: selectedBook, link: `` },
  ];

  // this for for api call when user writes the question and hit enter of press send button this function has been calling
  const handleSubmit = () => {
    setQuestion(inputQuestion);
    setInputQuestion("");
    setLoading(true);
    setShow(true);
    let data = JSON.stringify({
      books: booksData,
      question: inputQuestion,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/books",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);

        setQAList([
          ...qaList,
          { question: inputQuestion, answer: response.data.result.content },
        ]);

        setAnswer("");
        if (response) {
          const storeData = JSON.stringify({
            email: user.email,
            question: inputQuestion,
            answer: response.data.result.content,
            course: "ai-fundamental-core",
            selected_course: selectedBook,
            week: secondRoute,
          });
          let configStore = {
            method: "post",
            url: "/api/store-response",
            headers: {
              "Content-Type": "application/json",
            },
            data: storeData,
          };
          axios
            .request(configStore)
            .then((response) => {})
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        setLoading(false);

        setQAList([
          ...qaList,
          {
            question: inputQuestion,
            answer: "Something went wrong please try again",
          },
        ]);

        setAnswer("");
        console.log(error);
      });
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };
  return (
    <div className={styles.section}>
      <Breadcrumb
        breadcrumbsList={
          selectedBook ? coinBreadcrumbsListSelected : coinBreadcrumbsList
        }
        setShow={setShow}
        setSelectBookNumber={setSelectBookNumber}
        setSelectedBook={setSelectedBook}
        setQAList={setQAList}
      />
      {show === true ? (
        <Chat
          question={question}
          setQuestion={setQuestion}
          answer={answer}
          setAnswer={setAnswer}
          loading={loading}
          qaList={qaList}
          selectedBook={selectedBook}
          setQAList={setQAList}
          handleSubmit={handleSubmit}
          handleEnterKeyPress={handleEnterKeyPress}
          inputQuestion={inputQuestion}
          setInputQuestion={setInputQuestion}
        />
      ) : (
        <div className={styles.container}>
          {typeof window !== "undefined" &&
          window.location.pathname === "/ai/week1/core" ? (
            <div className={styles.container__div}>
              <SingleWeekCoreData
                week="week1"
                tab="core"
                course="ai"
                title="Week 1 : Introduction to AI and Machine Learning"
                bookName1="Purpose of session"
                bookName2="The AI Triad and What It Means for National Security Strategy"
                bookName3="Visualizing the deep learning revolution"
                bookName4="But what is a neural network?"
                bookName5="Gradient descent, how neural networks learn"
                bookName6="A short introduction to Machine Learning"
                bookName7="How ChatGPT Works Technically"
                bookName1Number="1"
                bookName2Number="2"
                bookName3Number="3"
                bookName4Number="4"
                bookName5Number="5"
                bookName6Number="6"
                bookName7Number="7"
                selectedBook={selectedBook}
                setSelectedBook={setSelectedBook}
                selectBookNumber={selectBookNumber}
                setSelectBookNumber={setSelectBookNumber}
                booksData={booksData}
                setBooksData={setBooksData}
                question={question}
                setQuestion={setQuestion}
                answer={answer}
                setAnswer={setAnswer}
                show={show}
                setShow={setShow}
                handleSubmit={handleSubmit}
                inputQuestion={inputQuestion}
                setInputQuestion={setInputQuestion}
              />
            </div>
          ) : typeof window !== "undefined" &&
            window.location.pathname === "/ai/week2/core" ? (
            <div className={styles.container__div}>
              <SingleWeekCoreData
                week="week2"
                tab="core"
                course="ai"
                title="Week 2 : Introduction to potential catastrophic risks from AI"
                bookName1="Purpose of session"
                bookName2="Overview of how AI might exacerbate long-running catastrophic risks"
                bookName3="As AI agents like Auto-GPT speed up generative AI race, we all need to buckle up"
                bookName4="The need for work on Technical AI Alignment"
                bookNam5="Specification gaming= the flip side of AI ingenuity"
                bookNam6="Researcher perceptions of Current and Future AI"
                bookName1Number="19"
                bookName2Number="20"
                bookName3Number="21"
                bookName4Number="22"
                bookName5Number="23"
                bookName6Number="24"
                selectedBook={selectedBook}
                setSelectedBook={setSelectedBook}
                selectBookNumber={selectBookNumber}
                setSelectBookNumber={setSelectBookNumber}
                booksData={booksData}
                setBooksData={setBooksData}
                question={question}
                setQuestion={setQuestion}
                answer={answer}
                setAnswer={setAnswer}
                show={show}
                setShow={setShow}
                handleSubmit={handleSubmit}
                inputQuestion={inputQuestion}
                setInputQuestion={setInputQuestion}
              />
            </div>
          ) : typeof window !== "undefined" &&
            window.location.pathname === "/ai/week3/core" ? (
            <div className={styles.container__div}>
              <SingleWeekCoreData
                week="week3"
                tab="core"
                course="ai"
                title="Week 3 : Challenges in achieving AI safety"
                bookName1="Purpose of session"
                bookName2="Emergent Deception and Emergent Optimisation"
                bookName3="AI Safety Seems Hard to Measure"
                bookName4="Compilation= Why Might Misaligned, Advanced AI Cause Catastrophe?"
                bookName5="Nobody’s on the ball on AGI alignment"
                bookName6="Avoiding Extreme Global Vulnerability as a Core AI Governance Problem"
                bookName1Number="36"
                bookName2Number="37"
                bookName3Number="38"
                bookName4Number="39"
                bookName5Number="40"
                bookName6Number="41"
                selectedBook={selectedBook}
                setSelectedBook={setSelectedBook}
                selectBookNumber={selectBookNumber}
                setSelectBookNumber={setSelectBookNumber}
                booksData={booksData}
                setBooksData={setBooksData}
                question={question}
                setQuestion={setQuestion}
                answer={answer}
                setAnswer={setAnswer}
                show={show}
                setShow={setShow}
                handleSubmit={handleSubmit}
                inputQuestion={inputQuestion}
                setInputQuestion={setInputQuestion}
              />
            </div>
          ) : typeof window !== "undefined" &&
            window.location.pathname === "/ai/week4/core" ? (
            <div className={styles.container__div}>
              <SingleWeekCoreData
                week="week4"
                tab="core"
                course="ai"
                title="Week 4 : AI standards and regulations"
                bookName1="Purpose of session"
                bookName2="Primer on Safety Standards and Regulations for Industrial-Scale AI Development"
                bookName3="Frontier AI Regulation= Managing Emerging Risks to Public Safety"
                bookName4="Model Evaluation for Extreme Risks"
                bookName5="Safety Evaluations and Standards for AI"
                bookName1Number="52"
                bookName2Number="53"
                bookName3Number="54"
                bookName4Number="55"
                bookName5Number="56"
                selectedBook={selectedBook}
                setSelectedBook={setSelectedBook}
                selectBookNumber={selectBookNumber}
                setSelectBookNumber={setSelectBookNumber}
                booksData={booksData}
                setBooksData={setBooksData}
                question={question}
                setQuestion={setQuestion}
                answer={answer}
                setAnswer={setAnswer}
                show={show}
                setShow={setShow}
                handleSubmit={handleSubmit}
                inputQuestion={inputQuestion}
                setInputQuestion={setInputQuestion}
              />
            </div>
          ) : typeof window !== "undefined" &&
            window.location.pathname === "/ai/week5/core" ? (
            <div className={styles.container__div}>
              <SingleWeekCoreData
                week="week5"
                tab="maybe"
                course="ai"
                title="Week 5 : Making AI regulations globally relevant through countries with regulations leading on AI"
                bookName1="Purpose of session"
                bookName2="Primer on the AI chip supply chain and export controls"
                bookName3="Introduction to Compute Governance"
                bookName4="The State of AI in various countries - an Overview"
                bookName5="Racing through a minefield= the AI deployment problem"
                bookName6="Winning the Tech Talent Competition"
                bookName1Number="64"
                bookName2Number="65"
                bookName3Number="66"
                bookName4Number="67"
                bookName5Number="68"
                bookName6Number="69"
                selectedBook={selectedBook}
                setSelectedBook={setSelectedBook}
                selectBookNumber={selectBookNumber}
                setSelectBookNumber={setSelectBookNumber}
                booksData={booksData}
                setBooksData={setBooksData}
                question={question}
                setQuestion={setQuestion}
                answer={answer}
                setAnswer={setAnswer}
                show={show}
                setShow={setShow}
                handleSubmit={handleSubmit}
                inputQuestion={inputQuestion}
                setInputQuestion={setInputQuestion}
              />
            </div>
          ) : typeof window !== "undefined" &&
            window.location.pathname === "ai/week6/core" ? (
            <div className={styles.container__div}>
              <SingleWeekCoreData
                week="week6"
                tab="core"
                course="ai"
                title="Week 6 : Making AI regulations globally relevant through international agreements"
                bookName1="Purpose of session"
                bookName2="What Does It Take To Catch a Chinchilla? Verifying Rules on Large-Scale Neural Network Training via Compute Monitoring"
                bookName3="A Tour of Emerging Cryptographic Technologies"
                bookName4="Deterring Nuclear Proliferation"
                bookName5="Overviews of Some Basic Models of Governments and International Cooperation"
                bookName1Number="78"
                bookName2Number="79"
                bookName3Number="80"
                bookName4Number="81"
                bookName5Number="82"
                selectedBook={selectedBook}
                setSelectedBook={setSelectedBook}
                selectBookNumber={selectBookNumber}
                setSelectBookNumber={setSelectBookNumber}
                booksData={booksData}
                setBooksData={setBooksData}
                question={question}
                setQuestion={setQuestion}
                answer={answer}
                setAnswer={setAnswer}
                show={show}
                setShow={setShow}
                handleSubmit={handleSubmit}
                inputQuestion={inputQuestion}
                setInputQuestion={setInputQuestion}
              />
            </div>
          ) : typeof window !== "undefined" &&
            window.location.pathname === "/ai/week7/core" ? (
            <div className={styles.container__div}>
              <SingleWeekCoreData
                week="week7"
                tab="core"
                course="ai"
                title="Week 7 : Additional proposals"
                bookName1="Purpose of session"
                bookName2="Placeholder] Norm-abiding strategy ideas"
                bookName3="OpenAI Charter"
                bookName4="LP Announcement"
                bookName5="What AI companies can do today to help with the most important century"
                bookName6="12 Tentative Ideas for US AI Policy"
                bookName7="Let’s think about slowing down AI"
                bookName8="AI Governance= A research agenda"
                bookName1Number="87"
                bookName2Number="88"
                bookName3Number="89"
                bookName4Number="90"
                bookName5Number="91"
                bookName6Number="92"
                bookName7Number="93"
                bookName8Number="94"
                selectedBook={selectedBook}
                setSelectedBook={setSelectedBook}
                selectBookNumber={selectBookNumber}
                setSelectBookNumber={setSelectBookNumber}
                booksData={booksData}
                setBooksData={setBooksData}
                question={question}
                setQuestion={setQuestion}
                answer={answer}
                setAnswer={setAnswer}
                show={show}
                setShow={setShow}
                handleSubmit={handleSubmit}
                inputQuestion={inputQuestion}
                setInputQuestion={setInputQuestion}
              />
            </div>
          ) : (
            typeof window !== "undefined" &&
            window.location.pathname === "/ai/week8/core" && (
              <div className={styles.container__div}>
                <SingleWeekCoreData
                  week="week8"
                  tab="core"
                  course="ai"
                  title="Week 8 : Career Advice"
                  bookName1="Purpose of session"
                  bookName2="Career Resources on AI Strategy Research"
                  bookName3="Career Resources on U.S. AI Policy"
                  bookName4="China-related AI safety and governance paths"
                  bookName5="Career Resources on European AI Policy"
                  bookName6="AI Governance Needs Technical Work"
                  bookName7="My current impressions on career choice for longtermists"
                  bookName8="Opportunities for Funding"
                  bookName1Number="105"
                  bookName2Number="106"
                  bookName3Number="107"
                  bookName4Number="108"
                  bookName5Number="109"
                  bookName6Number="110"
                  bookName7Number="111"
                  bookName8Number="112"
                  selectedBook={selectedBook}
                  setSelectedBook={setSelectedBook}
                  selectBookNumber={selectBookNumber}
                  setSelectBookNumber={setSelectBookNumber}
                  booksData={booksData}
                  setBooksData={setBooksData}
                  question={question}
                  setQuestion={setQuestion}
                  answer={answer}
                  setAnswer={setAnswer}
                  show={show}
                  setShow={setShow}
                  handleSubmit={handleSubmit}
                  inputQuestion={inputQuestion}
                  setInputQuestion={setInputQuestion}
                />
              </div>
            )
          )}
        </div>
      )}
      <Footer />
    </div>
  );
};

export default SingleWeekCore;
