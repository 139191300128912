import React from "react";

import { auth, provider } from "./firebase";
import axios from "axios";
import { FcGoogle } from "react-icons/fc";

import Logo from "../assests/3.png";

const Login = (props) => {
  const handleGmailLogin = async () => {
    auth
      .signInWithPopup(provider)
      .then((response) => {
        if (response) {
          axios
            .post("api/user", {
              email: response.additionalUserInfo.profile.email,
              name: response.additionalUserInfo.profile.name,
              firebaseId: response.additionalUserInfo.profile.id,
            })
            .then((result) => {})
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch(alert);
  };

  return (
    <section className="font-Poppins p-3 flex flex-row justify-center h-screen items-center">
      <div className="w-full md:w-[40%] px-4 md:px-12">
        <div className="flex flex-row justify-center items-center">
          <img src={Logo} alt="Logo" className="h-8 w-8" />
          <div className="ml-2 text-[22px] text-[#000] flex flex-row justify-center font-bold">
            Biosecurity Fundamentals
          </div>
        </div>
        <div className="text-[22px] text-[#000] flex flex-row justify-center mt-8 font-bold">
          Sign Up
        </div>
        <div className="text-base text-[#000] flex flex-row justify-center text-center mt-8">
          Personalize your experience, store searches, and enjoy tailored
          recommendations – sign up now!
        </div>
        <div>
          <div
            className="flex flex-row justify-center bg-white  text-black border-2 border-[#000000]/10 rounded-[6px] px-4 py-3 my-4 mt-8 hover:bg-[#f7f7f7] cursor-pointer font-Poppins"
            onClick={handleGmailLogin}
          >
            <div>
              <FcGoogle size={25} className="mr-4" />
            </div>
            Sign in with google
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
