import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import SingleWeekCoreData from "../HomePage/SingleWeekCoreData";
import Footer from "../HomePage/Footer";
import Breadcrumb from "../BreadCrumb/BreadCrumb";
import Chat from "../HomePage/Chat";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../Login/firebase";

const styles = {
  section: "flex flex-col flex-1 w-full h-full  pt-20 sm:pt-28",
  container: "flex flex-col flex-1 w-full h-full items-center",
  container__div: "flex flex-col w-full flex-1",
};

const BioFurther = () => {
  const containerRef = useRef(null);
  const [user] = useAuthState(auth);
  const [qaList, setQAList] = useState([]);
  const [selectBookNumber, setSelectBookNumber] = useState("");
  const [selectedBook, setSelectedBook] = useState("");
  const [booksData, setBooksData] = useState("");
  const [question, setQuestion] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [answer, setAnswer] = useState("");

  const secondRoute = window.location.pathname.split("/")[2];
  const [inputQuestion, setInputQuestion] = useState("");

  const coinBreadcrumbsList = [
    { title: "Home", link: "/" },
    { title: "Bio Fundamentals", link: `/bio` },
    { title: secondRoute, link: `` },
  ];
  const coinBreadcrumbsListSelected = [
    { title: "Home", link: "/" },
    { title: "Bio Fundamentals", link: `/bio` },
    { title: selectedBook, link: `` },
  ];

  // this for for api call when user writes the question and hit enter of press send button this function has been calling
  const handleSubmit = () => {
    setQuestion(inputQuestion);
    setInputQuestion("");
    setLoading(true);
    setShow(true);
    let data = JSON.stringify({
      question: inputQuestion,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/books",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);

        setQAList([
          ...qaList,
          { question: inputQuestion, answer: response.data.result.content },
        ]);
        // Clear the input field

        setAnswer("");
        if (response) {
          const storeData = JSON.stringify({
            email: user.email,
            question: inputQuestion,
            answer: response.data.result.content,
            course: "bio-further",
            selected_course: selectedBook,
            week: secondRoute,
          });
          let configStore = {
            method: "post",
            url: "/api/store-response",
            headers: {
              "Content-Type": "application/json",
            },
            data: storeData,
          };
          axios
            .request(configStore)
            .then((response) => {})
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        setLoading(false);

        setQAList([
          ...qaList,
          {
            question,
            answer:
              "Something went wrong please try with another course or week",
          },
        ]);

        setAnswer("");
        console.log(error);
      });
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    // Automatically scroll to the bottom when new content is added
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [qaList]);
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className={styles.section}>
      <Breadcrumb
        breadcrumbsList={
          selectedBook ? coinBreadcrumbsListSelected : coinBreadcrumbsList
        }
        setShow={setShow}
        setSelectBookNumber={setSelectBookNumber}
        setSelectedBook={setSelectedBook}
        setQAList={setQAList}
      />
      {show === true ? (
        <Chat
          question={question}
          setQuestion={setQuestion}
          answer={answer}
          setAnswer={setAnswer}
          loading={loading}
          qaList={qaList}
          selectedBook={selectedBook}
          setQAList={setQAList}
          handleSubmit={handleSubmit}
          handleEnterKeyPress={handleEnterKeyPress}
          inputQuestion={inputQuestion}
          setInputQuestion={setInputQuestion}
        />
      ) : (
        <div className={styles.container}>
          {typeof window !== "undefined" &&
          window.location.pathname === "/bio/week1/further" ? (
            <div className={styles.container__div}>
              <SingleWeekCoreData
                week="week1"
                tab="further"
                course="bio"
                title="Week 1 : Intro to biosecurity and sources of pandemic risk"
                bookName1="Reducing global catastrophic biological risks"
                bookName1Number="8"
                selectedBook={selectedBook}
                setSelectedBook={setSelectedBook}
                selectBookNumber={selectBookNumber}
                setSelectBookNumber={setSelectBookNumber}
                booksData={booksData}
                setBooksData={setBooksData}
                question={question}
                setQuestion={setQuestion}
                answer={answer}
                setAnswer={setAnswer}
                show={show}
                setShow={setShow}
                handleSubmit={handleSubmit}
                inputQuestion={inputQuestion}
                setInputQuestion={setInputQuestion}
              />
            </div>
          ) : typeof window !== "undefined" &&
            window.location.pathname === "/bio/week2/further" ? (
            <div className={styles.container__div}>
              <SingleWeekCoreData
                week="week2"
                tab="further"
                course="bio"
                title="Week 2 :  Malicious actors and advances in biotechnology"
                bookName1="Central dogma of molecular biology"
                bookName2="The Most Complex Language in the World"
                bookName3="History of Biological Weapons: from poisoned darts to intentional epidemics"
                bookName4="Synthetic Biology Video Playlist"
                bookName5="How AI Can Help Prevent Biosecurity Disasters"
                bookName1Number="15"
                bookName2Number="16"
                bookName3Number="17"
                bookName4Number="19"
                bookName5Number="20"
                selectedBook={selectedBook}
                setSelectedBook={setSelectedBook}
                selectBookNumber={selectBookNumber}
                setSelectBookNumber={setSelectBookNumber}
                booksData={booksData}
                setBooksData={setBooksData}
                question={question}
                setQuestion={setQuestion}
                answer={answer}
                setAnswer={setAnswer}
                show={show}
                setShow={setShow}
                handleSubmit={handleSubmit}
                inputQuestion={inputQuestion}
                setInputQuestion={setInputQuestion}
              />
            </div>
          ) : typeof window !== "undefined" &&
            window.location.pathname === "/bio/week3/further" ? (
            <div className={styles.container__div}>
              <SingleWeekCoreData
                week="week3"
                tab="further"
                course="bio"
                title="Week 3 :  Frameworks for evaluating biosecurity interventions"
                bookName1="A Framework for Technical Progress on Biosecurity"
                bookName2="UK Biological Security Strategy"
                bookName1Number="26"
                bookName2Number="27"
                selectedBook={selectedBook}
                setSelectedBook={setSelectedBook}
                selectBookNumber={selectBookNumber}
                setSelectBookNumber={setSelectBookNumber}
                booksData={booksData}
                setBooksData={setBooksData}
                question={question}
                setQuestion={setQuestion}
                answer={answer}
                setAnswer={setAnswer}
                show={show}
                setShow={setShow}
                handleSubmit={handleSubmit}
                inputQuestion={inputQuestion}
                setInputQuestion={setInputQuestion}
              />
            </div>
          ) : null}
          <Footer />
        </div>
      )}
    </div>
  );
};

export default BioFurther;
