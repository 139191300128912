import React from "react";
// import { LuCircleEqual, LuX } from "react-icons/lu";

import {
  HiThumbDown,
  HiThumbUp,
  HiOutlineThumbDown,
  HiOutlineThumbUp,
} from "react-icons/hi";

function FeedbackBlock({ liked_gpt_response, like, disLike, ...props }) {
  return (
    <div className="relative flex flex-col items-start space-y-2 pl-3  py-2 mt-12 sm:space-y-0 sm:flex-row  sm:items-center sm:justify-between border  sm:mt-4 sm:px-4 sm:py-4 bg-gray-400/10  rounded-md mx-auto w-[300px] sm:w-full sm:max-w-3xl sm:mx-auto">
      <p className="text-sm font-normal  text-[#40414f] ">
        Was this response useful or not?
      </p>
      <div className="flex  space-x-6 sm:items-center sm:justify-start  sm:space-x-10">
        <button className="flex items-center sm:hover:bg-gray-300 sm:px-2 sm:py-2 sm:rounded-md sm:items-center sm:flex    ">
          {liked_gpt_response === "true" || like === true ? (
            <HiThumbUp
              strokeWidth={2}
              className="mr-1.5 h-4 w-4 sm:mr-2 sm:h-5 sm:w-5 text-[#18b13f]"
              color="#18b13f"
            />
          ) : (
            <HiOutlineThumbUp
              strokeWidth={2}
              className="mr-1.5 h-4 w-4 sm:mr-2 sm:h-5 sm:w-5 text-gray-700"
              onClick={() => props.likeResponse(props.question, props.answer)}
            />
          )}

          <p className="text-sm    font-normal text-gray-700">good</p>
        </button>

        <button className="flex items-center sm:hover:bg-gray-300 sm:px-2 sm:py-2 sm:rounded-md sm:items-center sm:flex    ">
          {liked_gpt_response === "false" || disLike === true ? (
            <HiThumbDown
              strokeWidth={2}
              className="mr-1.5 h-4 w-4 sm:mr-2 sm:h-5 sm:w-5 text-[#FF0000]"
              color="#FF0000"
            />
          ) : (
            <HiOutlineThumbDown
              strokeWidth={2}
              className="mr-1.5 h-4 w-4 sm:mr-2 sm:h-5 sm:w-5 text-gray-700 cursor-pointer"
              onClick={() =>
                props.disLikeResponse(props.question, props.answer)
              }
            />
          )}

          <p className="text-sm     font-normal text-gray-700">bad</p>
        </button>

        {/* 
        <button className="flex items-center sm:hover:bg-gray-300 px-2 sm:py-2 sm:rounded-md sm:items-center sm:flex   ">
          <LuCircleEqual
            strokeWidth={2}
            className="mr-1.5 h-4 w-4 sm:mr-2 sm:h-5 sm:w-5 text-gray-700"
          />
          <p className="text-sm     font-normal text-gray-700">Same</p>
        </button>

        <button className=" absolute top-2 right-2 sm:hover:bg-gray-300 sm:px-2 sm:py-2 sm:rounded-md sm:relative sm:top-0 sm:right-0 sm:flex">
          <LuX strokeWidth={2} className=" h-5 w-5 text-gray-700" />
        </button>
        */}
      </div>
    </div>
  );
}

export default FeedbackBlock;
