import React from "react";
import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import SingleWeekCore from "./HomePage/SingleWeekCore";
import SingleWeekFurther from "./HomePage/SingleWeekFurther";
import SingleWeekMaybe from "./HomePage/SingleWeekMaybe";
import Navbar from "./HomePage/Navbar";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./Login/firebase";
import Login from "./Login/Login";
import MainHomepage from "./MainHomePage/MainHomePage";
import BioCore from "./BioCourseHomePage/BioCore";
import BioFurther from "./BioCourseHomePage/BioFurther";
import BioCourseHomepage from "./BioCourseHomePage/BioCouseHomePage";
import Homepage from "./HomePage/Homepage";

function App(props) {
  const [user] = useAuthState(auth);

  return (
    <div className="flex flex-col min-h-screen font-poppins text-[#40414f] w-full mx-auto ">
      <Router>
        <Navbar />
        {user ? (
          <Switch>
            <Route exact path="/ai/:week/core">
              <SingleWeekCore />
            </Route>
            <Route exact path="/ai/:week/further">
              <SingleWeekFurther />
            </Route>
            <Route exact path="/ai/:week/maybe">
              <SingleWeekMaybe />
            </Route>
            <Route exact path="/ai">
              <Homepage />
            </Route>
            <Route exact path="/bio/:week/further">
              <BioFurther />
            </Route>
            <Route exact path="/bio/:week/core">
              <BioCore />
            </Route>
            <Route exact path="/">
              <BioCore />
            </Route>
            {/* 
            <Route path="/">
              <MainHomepage />
            </Route>*/}
          </Switch>
        ) : (
          <Login />
        )}
      </Router>
    </div>
  );
}

export default App;
