import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import SingleWeekCoreData from "./SingleWeekCoreData";
import Footer from "./Footer";
import Breadcrumb from "../BreadCrumb/BreadCrumb";
import Chat from "./Chat";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../Login/firebase";

const styles = {
  section: "flex flex-col flex-1 w-full h-full  pt-20 sm:pt-28",
  container: "flex flex-col flex-1 w-full h-full items-center",
  container__div: "flex flex-col w-full flex-1",
};

const SingleWeekFurther = () => {
  const containerRef = useRef(null);
  const [user] = useAuthState(auth);
  const [qaList, setQAList] = useState([]);
  const [selectBookNumber, setSelectBookNumber] = useState("");
  const [selectedBook, setSelectedBook] = useState("");
  const [booksData, setBooksData] = useState("");
  const [question, setQuestion] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [answer, setAnswer] = useState("");

  const [inputQuestion, setInputQuestion] = useState("");

  const secondRoute = window.location.pathname.split("/")[2];

  const coinBreadcrumbsList = [
    { title: "Home", link: "/" },
    { title: "AI Safety Fundamentals", link: `/ai` },
    { title: secondRoute, link: `` },
  ];
  const coinBreadcrumbsListSelected = [
    { title: "Home", link: "/" },
    { title: "AI Safety Fundamentals", link: `/ai` },
    { title: selectedBook, link: `` },
  ];

  // this for for api call when user writes the question and hit enter of press send button this function has been calling
  const handleSubmit = () => {
    setQuestion(inputQuestion);
    setInputQuestion("");
    setLoading(true);
    setShow(true);
    let data = JSON.stringify({
      books: booksData,
      question: inputQuestion,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/books",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);

        setQAList([
          ...qaList,
          { question: inputQuestion, answer: response.data.result.content },
        ]);
        // Clear the input field

        setAnswer("");
        if (response) {
          const storeData = JSON.stringify({
            email: user.email,
            question: inputQuestion,
            answer: response.data.result.content,
            course: "ai-fundamental-further",
            selected_course: selectedBook,
            week: secondRoute,
          });
          let configStore = {
            method: "post",
            url: "/api/store-response",
            headers: {
              "Content-Type": "application/json",
            },
            data: storeData,
          };
          axios
            .request(configStore)
            .then((response) => {})
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        setLoading(false);

        setQAList([
          ...qaList,
          {
            question,
            answer:
              "Something went wrong please try with another course or week",
          },
        ]);

        setAnswer("");
        console.log(error);
      });
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    // Automatically scroll to the bottom when new content is added
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [qaList]);
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className={styles.section}>
      <Breadcrumb
        breadcrumbsList={
          selectedBook ? coinBreadcrumbsListSelected : coinBreadcrumbsList
        }
        setShow={setShow}
        setSelectBookNumber={setSelectBookNumber}
        setSelectedBook={setSelectedBook}
        setQAList={setQAList}
      />
      {show === true ? (
        <Chat
          question={question}
          setQuestion={setQuestion}
          answer={answer}
          setAnswer={setAnswer}
          loading={loading}
          qaList={qaList}
          selectedBook={selectedBook}
          setQAList={setQAList}
          handleSubmit={handleSubmit}
          handleEnterKeyPress={handleEnterKeyPress}
          inputQuestion={inputQuestion}
          setInputQuestion={setInputQuestion}
        />
      ) : (
        <div className={styles.container}>
          {typeof window !== "undefined" &&
          window.location.pathname === "/ai/week1/further" ? (
            <div className={styles.container__div}>
              <SingleWeekCoreData
                week="week1"
                tab="further"
                course="ai"
                title="Week 1 : Introduction to AI and Machine Learning"
                bookName1="The Main Resource is the Human"
                bookName2="Revisiting Algorithmic Progress"
                bookName3="Epoch AI Blog"
                bookName4="Will we run out of ML data? Evidence from projecting dataset size trends"
                bookName5="This Can't Go On"
                bookName6="The Bitter Lesson"
                bookName7="Introduction to reinforcement learning"
                bookName8="Three Impacts of Machine Intelligence"
                bookName9="Collection of GPT-3 results"
                bookName10="AlphaStar: mastering the real-time strategy game StarCraft II"
                bookName11="Generally capable agents emerge from open-ended play"
                bookName1Number="8"
                bookName2Number="9"
                bookName3Number="10"
                bookName4Number="11"
                bookName5Number="12"
                bookName6Number="13"
                bookName7Number="14"
                bookName8Number="15"
                bookName9Number="16"
                bookName10Number="17"
                bookName11Number="18"
                selectedBook={selectedBook}
                setSelectedBook={setSelectedBook}
                selectBookNumber={selectBookNumber}
                setSelectBookNumber={setSelectBookNumber}
                booksData={booksData}
                setBooksData={setBooksData}
                question={question}
                setQuestion={setQuestion}
                answer={answer}
                setAnswer={setAnswer}
                show={show}
                setShow={setShow}
                handleSubmit={handleSubmit}
                inputQuestion={inputQuestion}
                setInputQuestion={setInputQuestion}
              />
            </div>
          ) : typeof window !== "undefined" &&
            window.location.pathname === "/ai/week2/further" ? (
            <div className={styles.container__div}>
              <SingleWeekCoreData
                week="week2"
                tab="further"
                course="ai"
                title="Week 2 : Introduction to potential catastrophic risks from AI"
                bookName1="What are some arguments for AI safety being less important?"
                bookName2="Specification gaming examples"
                bookName3="Can large language models democratize access to dual-use biotechnology?"
                bookName4="Forecasting Transformative AI, Part 1: What Kind of AI?"
                bookName5="AGI safety from first principles: Superintelligence"
                bookName6="Why AI Alignment could be hard with modern deep learning"
                bookName7="AI Safety Fundamentals: Alignment Course"
                bookName8="What Everyone in Technical Alignment is Doing and Why"
                bookName9="Unsolved Problems in ML Safety"
                bookName10="Without specific countermeasures, the easiest path to transformative AI likely leads to AI takeover"
                bookName11="Survey on AI existential risk scenarios"
                bookName1Number="25"
                bookName2Number="26"
                bookName3Number="27"
                bookName4Number="28"
                bookName5Number="29"
                bookName6Number="30"
                bookName7Number="31"
                bookName8Number="32"
                bookName9Number="33"
                bookName10Number="34"
                bookName11Number="35"
                selectedBook={selectedBook}
                setSelectedBook={setSelectedBook}
                selectBookNumber={selectBookNumber}
                setSelectBookNumber={setSelectBookNumber}
                booksData={booksData}
                setBooksData={setBooksData}
                question={question}
                setQuestion={setQuestion}
                answer={answer}
                setAnswer={setAnswer}
                show={show}
                setShow={setShow}
                handleSubmit={handleSubmit}
                inputQuestion={inputQuestion}
                setInputQuestion={setInputQuestion}
              />
            </div>
          ) : typeof window !== "undefined" &&
            window.location.pathname === "/ai/week3/further" ? (
            <div className={styles.container__div}>
              <SingleWeekCoreData
                week="week3"
                tab="further"
                course="ai"
                title="Week 3 : Challenges in achieving AI safety"
                bookName1="What are some arguments for AI safety being less important?"
                bookName2="Goal Misgeneralisation: Why Correct Specifications Aren’t Enough For Correct Goals"
                bookName3="What failure looks like"
                bookName4="Goal Misgeneralisation examples"
                bookName5="The other alignment problem: mesa-optimisers and inner alignment"
                bookName6="Beyond Near- and Long-Term: Towards a Clearer Account of Research Priorities in AI Ethics and Society"
                bookName7={`AI Timelines: Where the Arguments, and the "Experts," Stand`}
                bookName8="Discontinuous progress in history: an update"
                bookName9="AI Governance: Opportunity and Theory of Impact"
                bookName10="Coordination challenges for preventing AI conflict"
                bookName1Number="42"
                bookName2Number="43"
                bookName3Number="44"
                bookName4Number="45"
                bookName5Number="46"
                bookName6Number="47"
                bookName7Number="48"
                bookName8Number="49"
                bookName9Number="50"
                bookName10Number="51"
                selectedBook={selectedBook}
                setSelectedBook={setSelectedBook}
                selectBookNumber={selectBookNumber}
                setSelectBookNumber={setSelectBookNumber}
                booksData={booksData}
                setBooksData={setBooksData}
                question={question}
                setQuestion={setQuestion}
                answer={answer}
                setAnswer={setAnswer}
                show={show}
                setShow={setShow}
                handleSubmit={handleSubmit}
                inputQuestion={inputQuestion}
                setInputQuestion={setInputQuestion}
              />
            </div>
          ) : typeof window !== "undefined" &&
            window.location.pathname === "/ai/week4/further" ? (
            <div className={styles.container__div}>
              <SingleWeekCoreData
                week="week4"
                tab="further"
                course="ai"
                title="Week 4 : AI standards and regulations"
                bookName1="Lessons From the World’s Two Experiments in AI Governance"
                bookName2="How technical safety standards could promote TAI safety"
                bookName3="Towards Best Practices in AGI Safety and Governance"
                bookName4="AI Risk Discussions"
                bookName5="AI Strategy, Policy, and Governance"
                bookName6="Transformative AI Governance: A Literature Review"
                bookName7="How to make the best of the most important century?"
                bookName1Number="57"
                bookName2Number="58"
                bookName3Number="59"
                bookName4Number="60"
                bookName5Number="61"
                bookName6Number="62"
                bookName7Number="63"
                selectedBook={selectedBook}
                setSelectedBook={setSelectedBook}
                selectBookNumber={selectBookNumber}
                setSelectBookNumber={setSelectBookNumber}
                booksData={booksData}
                setBooksData={setBooksData}
                question={question}
                setQuestion={setQuestion}
                answer={answer}
                setAnswer={setAnswer}
                show={show}
                setShow={setShow}
                handleSubmit={handleSubmit}
                inputQuestion={inputQuestion}
                setInputQuestion={setInputQuestion}
              />
            </div>
          ) : typeof window !== "undefined" &&
            window.location.pathname === "/ai/week5/further" ? (
            <div className={styles.container__div}>
              <SingleWeekCoreData
                week="week5"
                tab="maybe"
                course="ai"
                title="Week 5 : Making AI regulations globally relevant through countries with regulations leading on AI"
                bookName1="Transformative AI and Compute - Reading List"
                bookName2="Choking Off China’s Access to the Future of AI"
                bookName3="The Semiconductor Supply Chain"
                bookName4="Semi Conductor Supply Chain Diagram"
                bookName5="The rise and importance of Secret Congress"
                bookName1Number="70"
                bookName2Number="71"
                bookName3Number="72"
                bookName4Number="73"
                bookName5Number="74"
                selectedBook={selectedBook}
                setSelectedBook={setSelectedBook}
                selectBookNumber={selectBookNumber}
                setSelectBookNumber={setSelectBookNumber}
                booksData={booksData}
                setBooksData={setBooksData}
                question={question}
                setQuestion={setQuestion}
                answer={answer}
                setAnswer={setAnswer}
                show={show}
                setShow={setShow}
                handleSubmit={handleSubmit}
                inputQuestion={inputQuestion}
                setInputQuestion={setInputQuestion}
              />
            </div>
          ) : typeof window !== "undefined" &&
            window.location.pathname === "ai/week6/further" ? (
            <div className={styles.container__div}>
              <SingleWeekCoreData
                week="week6"
                tab="further"
                course="ai"
                title="Week 6 : Making AI regulations globally relevant through international agreements"
                bookName1="Nuclear proliferation (and Nonproliferation) Explained"
                bookName2="Information security considerations for AI and the long term future"
                bookName3="Syllabus: Artificial Intelligence and International Security"
                bookName4="Syllabus: Artificial Intelligence and China"
                bookName1Number="83"
                bookName2Number="84"
                bookName3Number="85"
                bookName4Number="86"
                selectedBook={selectedBook}
                setSelectedBook={setSelectedBook}
                selectBookNumber={selectBookNumber}
                setSelectBookNumber={setSelectBookNumber}
                booksData={booksData}
                setBooksData={setBooksData}
                question={question}
                setQuestion={setQuestion}
                answer={answer}
                setAnswer={setAnswer}
                show={show}
                setShow={setShow}
                handleSubmit={handleSubmit}
                inputQuestion={inputQuestion}
                setInputQuestion={setInputQuestion}
              />
            </div>
          ) : typeof window !== "undefined" &&
            window.location.pathname === "/ai/week7/further" ? (
            <div className={styles.container__div}>
              <SingleWeekCoreData
                week="week7"
                tab="further"
                course="ai"
                title="Week 7 : Additional proposals"
                bookName1="Cooperative AI"
                bookName2="International Institutions for Advanced AI"
                bookName3="Charting a Path to AI Accountability"
                bookName4="Governance of superintelligence"
                bookName5="How should AI systems behave, and who should decide?"
                bookName6="How do we best govern AI?"
                bookName7="Survey on Intermediate goals in AI governance"
                bookName8="AI Policy Levers"
                bookName9="Activism by the AI Community"
                bookName10="The role of philanthropic funding in politics"
                bookName1Number="95"
                bookName2Number="96"
                bookName3Number="97"
                bookName4Number="98"
                bookName5Number="99"
                bookName6Number="100"
                bookName7Number="101"
                bookName8Number="102"
                bookName9Number="103"
                bookName10Number="104"
                selectedBook={selectedBook}
                setSelectedBook={setSelectedBook}
                selectBookNumber={selectBookNumber}
                setSelectBookNumber={setSelectBookNumber}
                booksData={booksData}
                setBooksData={setBooksData}
                question={question}
                setQuestion={setQuestion}
                answer={answer}
                setAnswer={setAnswer}
                show={show}
                setShow={setShow}
                handleSubmit={handleSubmit}
                inputQuestion={inputQuestion}
                setInputQuestion={setInputQuestion}
              />
            </div>
          ) : (
            typeof window !== "undefined" &&
            window.location.pathname === "/ai/week8/further" && (
              <div className={styles.container__div}>
                <SingleWeekCoreData
                  week="week8"
                  tab="further"
                  course="ai"
                  title="Week 8 : Career Advice"
                  bookName1="Career Review: AI Governance and Coordination"
                  bookName2="Should you go to law school in the US to have a high-impact career?"
                  bookName3="Collection of AI Safety Newsletters"
                  bookName1Number="113"
                  bookName2Number="114"
                  bookName3Number="115"
                  selectedBook={selectedBook}
                  setSelectedBook={setSelectedBook}
                  selectBookNumber={selectBookNumber}
                  setSelectBookNumber={setSelectBookNumber}
                  booksData={booksData}
                  setBooksData={setBooksData}
                  question={question}
                  setQuestion={setQuestion}
                  answer={answer}
                  setAnswer={setAnswer}
                  show={show}
                  setShow={setShow}
                  handleSubmit={handleSubmit}
                  inputQuestion={inputQuestion}
                  setInputQuestion={setInputQuestion}
                />
              </div>
            )
          )}
          <Footer />
        </div>
      )}
    </div>
  );
};

export default SingleWeekFurther;
