import React, { useEffect, useRef } from "react";
import { MdSend } from "react-icons/md";
import axios from "axios";
import { Tooltip } from "react-tooltip";
import SelectedBook from "./SelectedBook";
import { Link } from "react-router-dom";
import Footer from "./Footer";

const styles = {
  section:
    "relative flex flex-col flex-1 w-full h-full items-center space-y-6 pt-[70px] pb-[94px] px-3 mb-10 sm:mb-0  sm:space-y-8 sm:pt-4 sm:px-20  ",
  section__title: "text-lg text-[#40414f] font-semibold text-center",
  container:
    "flex flex-col gap-4 sm:justify-center sm:flex-row sm:flex-wrap sm:gap-2 sm:pb-6  sm:max-w-4xl animate-slideInFromBottom ",
  chat__input__box:
    "fixed flex flex-col bottom-0 flex h-24 py-4 sm:h-32 sm:py-0 w-full  bg-white",
  input__container: " w-full max-w-full    sm:max-w-4xl sm:mx-auto",
  input__box:
    "flex items-center justify-between   px-2 py-2 mx-auto w-full  max-w-[300px] sm:max-w-4xl sm:flex-1  sm:mx-0  sm:px-6 sm:space-x-5 sm:py-2 border-[1px] border-[#d9d9e3]   bg-white shadow-xl rounded-xl",
  input__box__input__disabled:
    "  py-2 px-0   w-[240px] sm:max-w-4xl sm:flex-1  sm:pr-0 sm:py-2  font-medium outline-none font-poppins text-sm  sm:text-text-[16px] transition-all duration-200 text-[#40414f] caret-gray-800 focus:outline-none bg-transparent sm:px-3",
  button__container__disabled:
    "flex items-center justify-between  px-2 h-[30px]  hover:opacity-50 text-white font-bold  rounded-md  disabled:cursor-not-allowed",
  button__icon__disabled: "h-6 w-6 text-gray-500/50",
  input__box__input:
    " py-2  px-0  w-[240px] sm:max-w-4xl sm:flex-1   sm:pr-0  sm:py-2  font-medium outline-none font-poppins text-sm  sm:text-text-[16px]  transition-all duration-200 text-[#40414f] caret-gray-800 focus:outline-none bg-transparent sm:px-3",
  button__container:
    "bg-[#19c37d] px-2 h-[30px]  animate-revealButton  hover:scale-[1.06] transition duration-300 ease-in text-white font-bold  rounded-md disabled:bg-gray-300 disabled:cursor-not-allowed",
  button__icon: "h-4 w-4 text-white",
};

const SingleWeekCoreData = (props) => {
  const textAreaRef = useRef(null);

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      props.handleSubmit();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();

      const start = textAreaRef.current.selectionStart;
      const end = textAreaRef.current.selectionEnd;

      let newValue =
        props?.inputQuestion.substring(0, start) +
        "\n" +
        props?.inputQuestion.substring(end);

      // Move the cursor to the next line
      textAreaRef.current.value = newValue;

      props.setInputQuestion(newValue);

      textAreaRef.current.selectionStart = textAreaRef.current.selectionEnd =
        start + 1;
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
      adjustTextAreaHeight();
    }
  };
  const scrollTextAreaToEnd = () => {
    if (textAreaRef.current) {
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.scrollTop = scrollHeight;
    }
  };

  const adjustTextAreaHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  };

  const handleChange = (e) => {
    props.setInputQuestion(e.target.value);

    if (props.inputQuestion.length < 0 && textAreaRef.current) {
      textAreaRef.current.style.height = "0px";
    }
  };
  const place = "bottom";
  const type = "dark";
  const effect = "float";

  return (
    <div className={styles.section}>
      {/* 
      <div className="flex justify-center border-b-2 sm:max-w-4xl w-full border-[#eeeff1]">
        <div className="flex space-x-4 -mb-0.5">
       

          <button
            className={`px-4 py-1 mr-2 font-semibold border-b-[3px]  focus:outline-none ${
              window.location.pathname === `/${props.course}/${props.week}/core`
                ? " border-[#18b13f]/70 transition ease-in duration-400 "
                : "border-transparent"
            }`}
            // onClick={() => handleTabClick("core")}
          >
            <Link
              to={`/${props.course}/${props.week}/core`}
              className="w-full h-full "
            >
              Core
            </Link>
          </button>

        

          <button
            className={`px-4 py-1 font-semibold border-b-[3px] focus:outline-none ${
              window.location.pathname ===
              `/${props.course}/${props.week}/further`
                ? "  border-[#18b13f]/70 transition ease-in duration-400 "
                : "border-transparent"
            }`}
            // onClick={() => handleTabClick("further")}
          >
            <Link
              to={`/${props.course}/${props.week}/further`}
              className="w-full h-full "
            >
              Further
            </Link>
          </button>

      
          {props?.tab === "maybe" ? (
            <button
              className={`px-4 py-1 font-semibold border-b-[3px] focus:outline-none ${
                window.location.pathname === `/${props.week}/maybe`
                  ? "  border-[#18b13f]/70 transition ease-in duration-400 "
                  : "border-transparent"
              }`}
              // onClick={() => handleTabClick("further")}
            >
              <Link to={`/ai/week5/maybe`} className="w-full h-full ">
                Maybe
              </Link>
            </button>
          ) : null}
        </div>
      </div>

      <div className={styles.container}>
        {props.bookName1 ? (
          <SelectedBook
            selectedBook={props.selectedBook}
            bookName={props.bookName1}
            setSelectedBook={props.setSelectedBook}
            setSelectBookNumber={props.setSelectBookNumber}
            bookNameNumber={props.bookName1Number}
          />
        ) : null}
        {props.bookName2 ? (
          <div className="">
            <SelectedBook
              selectedBook={props.selectedBook}
              bookName={props.bookName2}
              setSelectedBook={props.setSelectedBook}
              setSelectBookNumber={props.setSelectBookNumber}
              bookNameNumber={props.bookName2Number}
            />
          </div>
        ) : null}

        {props.bookName3 ? (
          <div className="">
            <SelectedBook
              selectedBook={props.selectedBook}
              bookName={props.bookName3}
              setSelectedBook={props.setSelectedBook}
              setSelectBookNumber={props.setSelectBookNumber}
              bookNameNumber={props.bookName3Number}
            />
          </div>
        ) : null}
        {props.bookName4 ? (
          <div className="">
            <SelectedBook
              selectedBook={props.selectedBook}
              bookName={props.bookName4}
              setSelectedBook={props.setSelectedBook}
              setSelectBookNumber={props.setSelectBookNumber}
              bookNameNumber={props.bookName4Number}
            />
          </div>
        ) : null}

        {props.bookName5 ? (
          <div className="">
            <SelectedBook
              selectedBook={props.selectedBook}
              bookName={props.bookName5}
              setSelectedBook={props.setSelectedBook}
              setSelectBookNumber={props.setSelectBookNumber}
              bookNameNumber={props.bookName5Number}
            />
          </div>
        ) : null}
        {props.bookName6 ? (
          <div className="">
            <SelectedBook
              selectedBook={props.selectedBook}
              bookName={props.bookName6}
              setSelectedBook={props.setSelectedBook}
              setSelectBookNumber={props.setSelectBookNumber}
              bookNameNumber={props.bookName6Number}
            />
          </div>
        ) : null}

        {props.bookName7 ? (
          <div className="">
            <SelectedBook
              selectedBook={props.selectedBook}
              bookName={props.bookName7}
              setSelectedBook={props.setSelectedBook}
              setSelectBookNumber={props.setSelectBookNumber}
              bookNameNumber={props.bookName7Number}
            />
          </div>
        ) : null}
        {props.bookName8 ? (
          <div className="">
            <SelectedBook
              selectedBook={props.selectedBook}
              bookName={props.bookName8}
              setSelectedBook={props.setSelectedBook}
              setSelectBookNumber={props.setSelectBookNumber}
              bookNameNumber={props.bookName8Number}
            />
          </div>
        ) : null}

        {props.bookName9 ? (
          <div className="">
            <SelectedBook
              selectedBook={props.selectedBook}
              bookName={props.bookName9}
              setSelectedBook={props.setSelectedBook}
              setSelectBookNumber={props.setSelectBookNumber}
              bookNameNumber={props.bookName9Number}
            />
          </div>
        ) : null}
        {props.bookName10 ? (
          <div className="">
            <SelectedBook
              selectedBook={props.selectedBook}
              bookName={props.bookName10}
              setSelectedBook={props.setSelectedBook}
              setSelectBookNumber={props.setSelectBookNumber}
              bookNameNumber={props.bookName10Number}
            />
          </div>
        ) : null}

        {props.bookName11 ? (
          <div className="">
            <SelectedBook
              selectedBook={props.selectedBook}
              bookName={props.bookName11}
              setSelectedBook={props.setSelectedBook}
              setSelectBookNumber={props.setSelectBookNumber}
              bookNameNumber={props.bookName11Number}
            />
          </div>
        ) : null}
        {props.bookName12 ? (
          <div className="">
            <SelectedBook
              selectedBook={props.selectedBook}
              bookName={props.bookName12}
              setSelectedBook={props.setSelectedBook}
              setSelectBookNumber={props.setSelectBookNumber}
              bookNameNumber={props.bookName12Number}
            />
          </div>
        ) : null}
      </div>

      {!props.selectedBook ? (
        <div className={styles.chat__input__box}>
          <div className={styles.input__container}>
            <div className={styles.input__box}>
              <input
                disabled
                className={styles.input__box__input__disabled}
                type="text"
                placeholder="Select the reading & ask any question related to it"
              />

              <button
                disabled
                className={StyleSheet.button__container__disabled}
              >
                <MdSend className={styles.button__icon__disabled} />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.chat__input__box}>
          <div className={styles.input__container}>
            <div className={styles.input__box}>
              <textarea
                autoFocus
                ref={textAreaRef}
                className={`${
                  styles.input__box__input
                } w-full font-medium leading-6 text-[14px]  custom-scroll ${
                  props.inputQuestion.length <= 0
                    ? " h-[40px] max-h-[40px] "
                    : "h-[60px] max-h-[80px]"
                }`}
                type="text"
                placeholder={`Ask any question related to ${props.selectedBook}`}
                value={props.inputQuestion}
                onChange={handleChange}
                onKeyPress={handleEnterKeyPress}
                onKeyDown={handleKeyDown}
                rows={1}
                style={{
                  overflowY: "auto",
                  resize: "none",
                  whiteSpace: "pre-wrap",
                  scrollbarWidth: "thin", // Set scrollbar width (Firefox)
                  scrollbarColor: "darkgray lightgray", // Set scrollbar color (Firefox)
                  WebkitScrollbarWidth: "thin",
                  cursorPointer: true,
                }}
              />
              {props.inputQuestion ? (
                <div className="relative">
                  <a
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Send message"
                  >
                    <button
                      onClick={props.handleSubmit}
                      className={styles.button__container}
                    >
                      <MdSend className={styles.button__icon} />
                    </button>
                  </a>
                  <Tooltip
                    id="my-tooltip"
                    place={place}
                    type={type}
                    effect={effect}
                    multiline={true}
                  />
                </div>
              ) : (
                <button disabled className={styles.button__container__disabled}>
                  <MdSend className={styles.button__icon__disabled} />
                </button>
              )}
            </div>
            {props?.inputQuestion.length >= 16000 && (
              <span className="text-xs px-2 text-red-500 font-poppins">
                Exceeded token limit of 16k, need to shorten text.
              </span>
            )}
          </div>
        </div>
      )}
    */}

      <div className={styles.chat__input__box}>
        <div className={styles.input__container}>
          <div className={styles.input__box}>
            <textarea
              autoFocus
              ref={textAreaRef}
              className={`${
                styles.input__box__input
              } w-full font-medium leading-6 text-[14px]  custom-scroll ${
                props.inputQuestion.length <= 0
                  ? " h-[40px] max-h-[40px] "
                  : "h-[60px] max-h-[80px]"
              }`}
              type="text"
              placeholder={`Start a chat regarding anything in this course…`}
              value={props.inputQuestion}
              onChange={handleChange}
              onKeyPress={handleEnterKeyPress}
              onKeyDown={handleKeyDown}
              rows={1}
              style={{
                overflowY: "auto",
                resize: "none",
                whiteSpace: "pre-wrap",
                scrollbarWidth: "thin", // Set scrollbar width (Firefox)
                scrollbarColor: "darkgray lightgray", // Set scrollbar color (Firefox)
                WebkitScrollbarWidth: "thin",
                cursorPointer: true,
              }}
            />
            {props.inputQuestion ? (
              <div className="relative">
                <a
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Send message"
                >
                  <button
                    onClick={props.handleSubmit}
                    className={styles.button__container}
                  >
                    <MdSend className={styles.button__icon} />
                  </button>
                </a>
                <Tooltip
                  id="my-tooltip"
                  place={place}
                  type={type}
                  effect={effect}
                  multiline={true}
                />
              </div>
            ) : (
              <button disabled className={styles.button__container__disabled}>
                <MdSend className={styles.button__icon__disabled} />
              </button>
            )}
          </div>
          {props?.inputQuestion.length >= 16000 && (
            <span className="text-xs px-2 text-red-500 font-poppins">
              Exceeded token limit of 16k, need to shorten text.
            </span>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default SingleWeekCoreData;
