import React, { useState } from "react";
import axios from "axios";
import SingleWeekCoreData from "../HomePage/SingleWeekCoreData";
import Footer from "../HomePage/Footer";
import Breadcrumb from "../BreadCrumb/BreadCrumb";
import Chat from "../HomePage/Chat";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../Login/firebase";

const styles = {
  section: "flex flex-col flex-1 w-full  h-full  pt-20 sm:pt-28 ",
  container: "flex flex-col flex-1 w-full h-full items-center",
  container__div: "flex flex-col w-full flex-1 ",
};

const BioCore = () => {
  const [user] = useAuthState(auth);
  const [qaList, setQAList] = useState([]);
  const [selectBookNumber, setSelectBookNumber] = useState("");
  const [selectedBook, setSelectedBook] = useState("");
  const [booksData, setBooksData] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const [inputQuestion, setInputQuestion] = useState("");

  const secondRoute = window.location.pathname.split("/")[2];

  const coinBreadcrumbsList = [{ title: "Biosecurity Fundamentals", link: `` }];
  const coinBreadcrumbsListSelected = [
    { title: "Biosecurity Fundamentals", link: `` },
  ];

  // this for for api call when user writes the question and hit enter of press send button this function has been calling
  const handleSubmit = () => {
    if (loading) return;
    setQuestion(inputQuestion);
    setInputQuestion("");
    setLoading(true);
    setShow(true);
    let data = JSON.stringify({
      question: inputQuestion,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/books",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);

        setQAList([
          ...qaList,
          { question: inputQuestion, answer: response.data.result.content },
        ]);

        setAnswer("");
        if (response) {
          const storeData = JSON.stringify({
            email: user.email,
            question: inputQuestion,
            answer: response.data.result.content,
            course: "bio-core",
            selected_course: selectedBook,
            week: secondRoute,
          });
          let configStore = {
            method: "post",
            url: "/api/store-response",
            headers: {
              "Content-Type": "application/json",
            },
            data: storeData,
          };
          axios
            .request(configStore)
            .then((response) => {})
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        setLoading(false);

        setQAList([
          ...qaList,
          {
            question: inputQuestion,
            answer: "Something went wrong please try again",
          },
        ]);

        setAnswer("");
        console.log(error);
      });
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className={styles.section}>
      <Breadcrumb
        breadcrumbsList={
          selectedBook ? coinBreadcrumbsListSelected : coinBreadcrumbsList
        }
        setShow={setShow}
        setSelectBookNumber={setSelectBookNumber}
        setSelectedBook={setSelectedBook}
        setQAList={setQAList}
      />
      {show === true ? (
        <Chat
          question={question}
          setQuestion={setQuestion}
          answer={answer}
          setAnswer={setAnswer}
          loading={loading}
          qaList={qaList}
          selectedBook={selectedBook}
          setQAList={setQAList}
          handleSubmit={handleSubmit}
          handleEnterKeyPress={handleEnterKeyPress}
          inputQuestion={inputQuestion}
          setInputQuestion={setInputQuestion}
        />
      ) : (
        <div className={styles.container}>
          <div className={styles.container__div}>
            <SingleWeekCoreData
              week="week1"
              tab="core"
              course="bio"
              title="Week 1 : Intro to biosecurity and sources of pandemic risk"
              bookName1="Biosecurity and the goals of this course"
              bookName2="Pandemic prevention as fire-fighting"
              bookName3="An illustrated history of the world’s deadliest epidemics, from ancient Rome to Covid-19"
              bookName4="What Are the Odds H5N1 Is Worse Than COVID-19?"
              bookName5="High-risk human-caused pathogen exposure events from 1975-2016"
              bookName6="The Danger of ‘Invisible’ Biolabs Across the U.S."
              bookName7="Info hazard guidance for biosecurity discussions"
              bookName1Number="1"
              bookName2Number="2"
              bookName3Number="3"
              bookName4Number="4"
              bookName5Number="5"
              bookName6Number="6"
              bookName7Number="7"
              selectedBook={selectedBook}
              setSelectedBook={setSelectedBook}
              selectBookNumber={selectBookNumber}
              setSelectBookNumber={setSelectBookNumber}
              booksData={booksData}
              setBooksData={setBooksData}
              question={question}
              setQuestion={setQuestion}
              answer={answer}
              setAnswer={setAnswer}
              show={show}
              setShow={setShow}
              handleSubmit={handleSubmit}
              inputQuestion={inputQuestion}
              setInputQuestion={setInputQuestion}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default BioCore;
