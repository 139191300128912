import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDNKKucHf8KAti1xbeE8s2SOMXAToF66tk",
  authDomain: "pilots-chat-gpt.firebaseapp.com",
  projectId: "pilots-chat-gpt",
  storageBucket: "pilots-chat-gpt.appspot.com",
  messagingSenderId: "745079198620",
  appId: "1:745079198620:web:1cf04494b7bcf1ec20ee59",
  measurementId: "G-HRH613PJFW",
};

const app = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();

const db = app.firebase.firestore();
const auth = app.firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export { db, auth, provider };
