import React from "react";

const styles = {
  footer: "flex flex-row  justify-center w-full px-2 md:px-24 py-2  mt-3",
  footer__text: "text-sm sm:text-md text-center font-medium text-[#000000]/70",
};

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.footer__text}>
        Project by :© 2023 BlueDot Impact. All rights reserved.
      </div>
      <a
        className={`ml-2 text-sm sm:text-md text-center font-medium text-[#0000FF]/70 border-b-[1px] border-[#0000FF]/70`}
        href="https://openai.com/policies/privacy-policy"
        target="_blank"
      >
        Privacy Policy
      </a>
    </div>
  );
};

export default Footer;
