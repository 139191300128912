import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { MdSend } from "react-icons/md";
import Breadcrumb from "../BreadCrumb/BreadCrumb";

// const style = {
//   divStyle:
//     "fade-in-hello flex flex-row mt-2 w-auto bg-[#fff] font-Poppins text-lg text-[#000]/90 font-normal border-[1px] px-4 py-2 border-[#000]/30 rounded-lg hover:bg-[#d9d9e3]/80 hover:border-[#000]/30  cursor-pointer",
// };

const styles = {
  section: "flex flex-col justify-between w-full h-full flex-1  px-3 sm:px-0  ",
  container: "flex flex-col space-y-6 py-20 sm:space-y-8 sm:py-32  ",
  container__heading: "text-lg text-[#40414f] font-semibold text-center",

  container__box: "flex flex-col gap-4 max-w-5xl  mx-auto h-full",
  column:
    "grid w-full grid-flow-row grid-cols-1 sm:grid-cols-2 gap-4 w-full h-full   ",
  column__box: "group relative flex flex-col h-full ",
  column__box__div:
    "absolute right-3 top-1/2 -translate-y-1/2 transform opacity-0 transition-opacity duration-200 ease-in group-hover:opacity-100",
  column__box__icon:
    "h-5 w-5 text-black animate-revealButton transition duration-200 ease-in",
  column__link:
    "flex flex-col justify-center space-y-2.5 pr-1 sm:pr-[30px]  w-full h-full fade-in-hello bg-white hover:bg-[#18b13f]/10 hover:border-[#18b13f]/30 transition ease-in duration-300 border-[1px] border-[#d9d9e3]",
  column__link__p: " font-semibold text-[18px] text-[#40414f]",
  column__link__div: " font-medium text-[16px]  text-gray-700/50",
};

const Homepage = () => {
  const coinBreadcrumbsList = [
    { title: "Home", link: "/" },
    { title: "AI Safety Fundamentals", link: `` },
  ];
  return (
    <div className={styles.section}>
      <Breadcrumb breadcrumbsList={coinBreadcrumbsList} />

      <div className={styles.container}>
        <div className={styles.container__heading}>
          Select the Course week from the below
        </div>
        <div className={styles.container__box}>
          <div className={styles.column}>
            <div className={styles.column__box}>
              <Link
                className={`info-text ${styles.column__link} `}
                to={`/ai/week1/core`}
              >
                <p className={styles.column__link__p}>Week 1</p>
                <div className={styles.column__link__div}>
                  Introduction to AI and Machine Learning
                </div>
              </Link>
              <div className={styles.column__box__div}>
                <MdSend className={styles.column__box__icon} />
              </div>
            </div>

            <div className={styles.column__box}>
              <Link
                className={`info-text ${styles.column__link}`}
                to={`/ai/week2/core`}
              >
                <p className={styles.column__link__p}>Week 2</p>
                <div className={styles.column__link__div}>
                  Introduction to potential catastrophic risks from AI
                </div>
              </Link>
              <div className={styles.column__box__div}>
                <MdSend className={styles.column__box__icon} />
              </div>
            </div>

            <div className={styles.column__box}>
              <Link
                className={`info-text ${styles.column__link}`}
                to={`ai/week3/core`}
              >
                <p className={styles.column__link__p}>Week 3</p>
                <div className={styles.column__link__div}>
                  Challenges in achieving AI safety
                </div>
              </Link>
              <div className={styles.column__box__div}>
                <MdSend className={styles.column__box__icon} />
              </div>
            </div>

            <div className={styles.column__box}>
              <Link
                className={`info-text ${styles.column__link}`}
                to={`ai/week4/core`}
              >
                <p className={styles.column__link__p}>Week 4</p>
                <div className={styles.column__link__div}>
                  AI standards and regulations
                </div>
              </Link>
              <div className={styles.column__box__div}>
                <MdSend className={styles.column__box__icon} />
              </div>
            </div>
          </div>

          <div className={styles.column}>
            <div className={styles.column__box}>
              <Link
                className={`info-text ${styles.column__link}`}
                to={`ai/week5/core`}
              >
                <p className={styles.column__link__p}>Week 5</p>
                <div className={styles.column__link__div}>
                  Making AI regulations globally relevant through countries with
                  regulations leading on AI
                </div>
              </Link>
              <div className={styles.column__box__div}>
                <MdSend className={styles.column__box__icon} />
              </div>
            </div>

            <div className={styles.column__box}>
              <Link
                className={`info-text ${styles.column__link}`}
                to={`ai/week6/core`}
              >
                <p className={styles.column__link__p}>Week 6</p>
                <div className={styles.column__link__div}>
                  Making AI regulations globally relevant through international
                  agreements
                </div>
              </Link>
              <div className={styles.column__box__div}>
                <MdSend className={styles.column__box__icon} />
              </div>
            </div>

            <div className={styles.column__box}>
              <Link
                className={`info-text ${styles.column__link}`}
                to={`ai/week7/core`}
              >
                <p className={styles.column__link__p}>Week 7</p>
                <div className={styles.column__link__div}>
                  Additional proposals
                </div>
              </Link>
              <div className={styles.column__box__div}>
                <MdSend className={styles.column__box__icon} />
              </div>
            </div>

            <div className={styles.column__box}>
              <Link
                className={`info-text ${styles.column__link}`}
                to={`ai/week8/core`}
              >
                <p className={styles.column__link__p}>Week 8</p>
                <div className={styles.column__link__div}>Career Advice</div>
              </Link>
              <div className={styles.column__box__div}>
                <MdSend className={styles.column__box__icon} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Homepage;
