import React from "react";
import Logo from "../assests/3.png";
import { Link } from "react-router-dom";
const Navbar = () => {
  return (
    <div className="fixed top-0  z-10 flex items-center justify-between h-[70px] bg-white   w-full mx-auto px-3 sm:px-0 ">
      <div className="max-w-7xl flex items-center justify-between mx-auto w-full ">
        <Link className="flex items-center space-x-1" to="/">
          <img className=" h-8 w-8 " src={Logo} alt="Logo" />
          <h3 className="text-lg md:text-xl font-cormorant  font-[700] text-[#40414f]">
            Biosecurity Fundamentals
          </h3>
        </Link>

        {/* <div className="flex">
        <Link to="/login">
          <button className="login-btn">Login</button>
        </Link>
      </div> */}
      </div>
    </div>
  );
};

export default Navbar;
