import React, { useRef, useEffect, useState } from "react";
import { MdSend } from "react-icons/md";

import { LuRefreshCw } from "react-icons/lu";

import { Tooltip } from "react-tooltip";
import User from "../assests/user.png";
import ChatBot from "../assests/3.png";
import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../Login/firebase";
import FeedbackBlock from "../FeedbackBlock/FeedbackBlock";
import Footer from "./Footer";

const styles = {
  section:
    "relative flex flex-col flex-1 w-full items-center  pt-[54px] sm:pt-0  ",
  chat__container: "flex flex-col flex-1 w-full h-5/6  mb-40 overflow-y-auto",

  chat__box: "flex-grow snap-mandatory z-0 relative",
  chat__box__question__1:
    "even:bg-gray-100  py-5  cssanimation sequence fadeInBottom ",
  chat__box__question__2: "even:bg-gray-100  py-5 border-t  border-[#d9d9e3]",
  chat__box__answer:
    "even:bg-gray-100 py-5 relative cssanimation sequence fadeInBottom border-t border-b  border-[#d9d9e3]",
  chat__box__question__div: "  flex space-x-3 px-3 max-w-4xl mx-auto ",
  chat__box__answer__div: " relative flex space-x-3  max-w-4xl mx-auto ",
  chat__box__question__div__img: "  h-10 w-10 rounded-md",
  chat__box__answer__div__img: "  ml-3 h-10 w-10 rounded-md mt-2",
  chat__box__question__div__p:
    "flex items-center text-base  text-[#40414f] font-medium font-inter leading-6",

  chat__input__container:
    "fixed bottom-0 flex w-full h-20 py-4 sm:h-40 sm:py-1 w-full  bg-white ",
  chat__input__box:
    " w-full   mx-auto max-w-full sm:relative sm:max-w-4xl sm:mx-auto",
  input__box:
    "flex items-center justify-between px-2 py-2 ml-1 mr-[44px] sm:mx-0 sm:mt-[20px] sm:px-6 sm:space-x-5 sm:py-2  border-[1px] border-[#d9d9e3]  bg-white shadow-xl rounded-xl ",
  input__box__input:
    " py-2 px-0 w-[240px]  sm:pr-0 sm:py-2  sm:max-w-4xl sm:flex-1  font-medium outline-none font-inter text-sm  sm:text-text-[16px] transition-all duration-200 text-[#40414f] caret-gray-800 focus:outline-none bg-transparent sm:px-3",

  button__container:
    "bg-[#19c37d] px-2 h-[30px]  hover:scale-[1.06] transition duration-300 ease-in text-white font-bold  rounded-md disabled:bg-gray-300 disabled:cursor-not-allowed",
  button__icon: "h-4 w-4 text-white",
  button__container__disabled:
    "flex items-center justify-center  px-2 h-[30px]  hover:opacity-50 text-white font-bold  rounded-md  disabled:cursor-not-allowed",
  button__icon__disabled: "h-6 w-6 text-gray-500/50 ",
  footer: "absolute left-0  bottom-0.5 flex flex-row justify-center w-full ",
  footer__text: "text-xs sm:text-md text-center font-medium text-gray-700/40",
};

const Chat = ({ handleEnterKeyPress, ...props }) => {
  const [user] = useAuthState(auth);
  const place = "bottom";
  const type = "dark";
  const effect = "float";

  const questionAnswerList = props.qaList;

  const textAreaRef = useRef(null);

  const TextNewLine = ({ newText }) => {
    const lines = newText.split("\n");

    // Map over the array and wrap each line with a <p> element
    const formattedText = lines.map((line, index) => (
      <p key={index} className="mt-2">
        {line.replace("#response:", "")}
      </p>
    ));

    return <span className="formatted_text">{formattedText}</span>;
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      // setIsShiftPressed(true);
      e.preventDefault();

      const start = textAreaRef.current.selectionStart;
      const end = textAreaRef.current.selectionEnd;

      let newValue =
        props?.inputQuestion.substring(0, start) +
        "\n" +
        props?.inputQuestion.substring(end);

      // Move the cursor to the next line
      textAreaRef.current.value = newValue;

      props.setInputQuestion(newValue);
      // adjustTextAreaHeight();
      // scrollTextAreaToEnd();
      textAreaRef.current.selectionStart = textAreaRef.current.selectionEnd =
        start + 1;
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
      adjustTextAreaHeight();
    }
  };

  const adjustTextAreaHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  };

  const handleChange = (e) => {
    props.setInputQuestion(e.target.value);

    if (props.inputQuestion.length < 0 && textAreaRef.current) {
      textAreaRef.current.style.height = "0px";
    }
  };

  const contentEndRef = useRef(null);

  useEffect(() => {
    if (contentEndRef.current) {
      contentEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [props.qaList]);
  const [displayResponse, setDisplayResponse] = useState("");
  const [completedTyping, setCompletedTyping] = useState(false);

  useEffect(() => {
    if (!questionAnswerList?.length) {
      return;
    }

    setCompletedTyping(false);

    let i = 0;
    const stringResponse =
      questionAnswerList[questionAnswerList.length - 1].answer;

    const intervalId = setInterval(() => {
      if (typeof stringResponse === "string") {
        setDisplayResponse(stringResponse.slice(0, i));

        i++;

        if (i > stringResponse.length) {
          clearInterval(intervalId);
          setCompletedTyping(true);
        }
      }
    }, 10);

    return () => clearInterval(intervalId);
  }, [questionAnswerList]);

  const [like, setLike] = useState(false);
  const [disLike, setDisLike] = useState(false);

  const likeResponse = (question, answer) => {
    const questionCheck = question;
    const storeData = JSON.stringify({
      email: user.email,
      question: question,
      answer: answer,
    });
    let configStore = {
      method: "put",
      url: "/api/store-response/like",
      headers: {
        "Content-Type": "application/json",
      },
      data: storeData,
    };
    axios
      .request(configStore)
      .then((response) => {
        setLike(true);
        const indexToUpdate = props.qaList.findIndex(
          (obj) => obj.question === questionCheck
        );

        // Check if the object with the specified property value is found
        if (indexToUpdate !== -1) {
          // Update the object at the found index by adding a new key-value pair
          props.qaList[indexToUpdate]["liked_gpt_response"] = "true";
          // The above line adds a new key 'newKey' with value 'New Value' to the object that matches the condition.
        } else {
          // Handle the case when the object with the specified property value is not found
          console.log("Object not found based on the specified property");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const disLikeResponse = (question, answer) => {
    const questionCheck = question;
    const storeData = JSON.stringify({
      email: user.email,
      question: question,
      answer: answer,
    });
    let configStore = {
      method: "put",
      url: "/api/store-response/output/dislike",
      headers: {
        "Content-Type": "application/json",
      },
      data: storeData,
    };
    axios
      .request(configStore)
      .then((response) => {
        setDisLike(true);
        const indexToUpdate = props.qaList.findIndex(
          (obj) => obj.question === questionCheck
        );
        console.log("osls", indexToUpdate);
        // Check if the object with the specified property value is found
        if (indexToUpdate !== -1) {
          // Update the object at the found index by adding a new key-value pair
          props.qaList[indexToUpdate]["liked_gpt_response"] = "false";
          // The above line adds a new key 'newKey' with value 'New Value' to the object that matches the condition.
        } else {
          // Handle the case when the object with the specified property value is not found
          console.log("Object not found based on the specified property");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (like) {
      setLike(true);
      const toRef = setTimeout(() => {
        setLike(false);
        clearTimeout(toRef);
      }, 2000);
    }
  }, [like]);
  useEffect(() => {
    if (disLike) {
      setDisLike(true);
      const toRef = setTimeout(() => {
        setDisLike(false);
        clearTimeout(toRef);
      }, 2000);
    }
  }, [disLike]);

  return (
    <div className={styles.section}>
      <div className={styles.chat__container}>
        <div className="h-5/6 overflow-y-auto">
          {questionAnswerList.map((ele, i) => {
            return (
              <div className={styles.chat__box} key={i}>
                {!ele.question ? (
                  <div className={styles.chat__box__question__1}>
                    <div className={styles.chat__box__question__div}>
                      <img
                        src={User}
                        alt="User"
                        className={styles.chat__box__question__div__img}
                      />
                      <p className={styles.chat__box__question__div__p}>
                        {" "}
                        {props.inputQuestion}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className={styles.chat__box__question__2}>
                    <div className={styles.chat__box__question__div}>
                      <img
                        src={User}
                        alt="User"
                        className={styles.chat__box__question__div__img}
                      />
                      <p className={styles.chat__box__question__div__p}>
                        {" "}
                        {ele.question}
                      </p>
                    </div>
                  </div>
                )}

                <div className={styles.chat__box__answer}>
                  <div className={styles.chat__box__answer__div}>
                    {/* 
                    <div className="absolute flex items-center   justify-between h-[20px] -bottom-8 left-[60px]  md:-left-[60px] md:top-5   ">
                      <div className="flex items-center space-x-1 w-full mr-1">
                        <LuChevronLeft className="h-3 w-3 text-gray-700" />
                        <p className="text-[12px] font-medium text-gray-700">
                          2
                        </p>
                      </div>
                      <div className="text-xs font-medium text-gray-700 ">
                        /
                      </div>
                      <div className="flex items-center space-x-1 w-full ml-1">
                        <p className="text-[12px] font-medium text-gray-700">
                          4
                        </p>
                        <LuChevronRight className="h-3 w-3 text-gray-700" />
                      </div>
                    </div>
                    */}
                    <img
                      src={ChatBot}
                      alt="Chat Bot"
                      className={styles.chat__box__answer__div__img}
                    />

                    {i !== questionAnswerList.length - 1 && (
                      <div className="chat chat-start">
                        <span className="chat-bubble whitespace-pre-line">
                          <TextNewLine newText={ele.answer} />
                        </span>
                      </div>
                    )}
                    {i === questionAnswerList.length - 1 && (
                      <div className="flex flex-row items-center">
                        <span className="flex flex-row items-center">
                          <TextNewLine
                            newText={displayResponse}
                            completedTyping={completedTyping}
                          />
                        </span>
                      </div>
                    )}
                  </div>
                  {completedTyping === true && (
                    <FeedbackBlock
                      liked_gpt_response={ele.liked_gpt_response}
                      like={like}
                      disLike={disLike}
                      question={ele.question}
                      answer={ele.answer}
                      likeResponse={likeResponse}
                      disLikeResponse={disLikeResponse}
                    />
                  )}
                </div>
              </div>
            );
          })}
          {props.loading === true && (
            <div className={styles.chat__box}>
              <div className={styles.chat__box__question__2}>
                <div className={styles.chat__box__question__div}>
                  <img
                    src={User}
                    alt="User"
                    className={styles.chat__box__question__div__img}
                  />
                  <p className={styles.chat__box__question__div__p}>
                    {" "}
                    {props.question}
                  </p>
                </div>
              </div>
              {/* blink start */}
              <div className={styles.chat__box__answer}>
                <div
                  className={`items-center ${styles.chat__box__question__div}`}
                >
                  <img
                    src={ChatBot}
                    alt="Chat Bot"
                    className={styles.chat__box__question__div__img}
                  />

                  <div className="blink"></div>
                </div>
              </div>
              {/* blink end */}
            </div>
          )}
          <div ref={contentEndRef} />
        </div>
      </div>
      {/* Chat Input  */}
      <div className={styles.chat__input__container}>
        <div className={styles.chat__input__box}>
          {/* 
          <button
            className="absolute hidden sm:flex right-0 top-2  items-center justify-center rounded-md font-medium hover:bg-gray-100 transition duration-300 ease-in bg-transparent px-2 h-[40px] border-2"
            onClick={props.handleSubmit}
            onKeyPress={handleEnterKeyPress}
          >
            <LuRefreshCw className="mr-2 h-4 w-4 text-gray-400" />
            Regenerate
          </button>
          */}
          <div className={styles.input__box}>
            <textarea
              autoFocus
              ref={textAreaRef}
              type="text"
              placeholder="Send a message"
              className={`${
                styles.input__box__input
              } w-full font-medium leading-6 text-[14px]  custom-scroll ${
                props.inputQuestion.length <= 0
                  ? " h-[40px] max-h-[40px] "
                  : "h-[60px] max-h-[80px]"
              }`}
              value={props.inputQuestion}
              onChange={handleChange}
              onKeyPress={handleEnterKeyPress}
              onKeyDown={handleKeyDown}
              rows={1}
              style={{
                overflowY: "auto",
                resize: "none",
                whiteSpace: "pre-wrap",
                scrollbarWidth: "thin", // Set scrollbar width (Firefox)
                scrollbarColor: "darkgray lightgray", // Set scrollbar color (Firefox)
                WebkitScrollbarWidth: "thin",
                cursorPointer: true,
              }}
            />
            {props.inputQuestion ? (
              <div className="relative">
                <a
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Send message"
                >
                  <button
                    className={styles.button__container}
                    onClick={props.handleSubmit}
                  >
                    <MdSend className={styles.button__icon} />
                  </button>
                </a>
                <Tooltip
                  id="my-tooltip"
                  place={place}
                  type={type}
                  effect={effect}
                  multiline={true}
                />
              </div>
            ) : (
              <button disabled className={styles.button__container__disabled}>
                <MdSend className={styles.button__icon__disabled} />
              </button>
            )}
          </div>
          {props?.inputQuestion.length >= 16000 && (
            <span className="text-xs px-2 text-red-500 font-poppins">
              Exceeded token limit of 16k, need to shorten text.
            </span>
          )}
          <Footer />
        </div>
        <button className="absolute sm:hidden right-2.5 top-[32px]  bg-transparent ">
          <LuRefreshCw strokeWidth={2} className=" h-5 w-5 text-gray-400" />
        </button>
      </div>
    </div>
  );
};

export default Chat;
